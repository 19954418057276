var htmlPage = function(htmlPageCrud) {

    this.bindEvents = function() {
        tinymce.init({
            selector: "textarea",
            theme: "modern",
            height: 300,
            language: 'it',
            paste_data_images: true,
            plugins: [
                "advlist autolink lists link image charmap print preview hr anchor pagebreak",
                "searchreplace wordcount visualblocks visualchars code fullscreen",
                "insertdatetime media nonbreaking save table contextmenu directionality",
                "template paste textcolor colorpicker textpattern"
            ],
            toolbar1: "insertfile undo redo | styleselect | bold italic | forecolor backcolor fontsizeselect | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link",
            //toolbar2: "preview ",
            menubar: false,
            fontsize_formats: '11px 17px 22px',
            init_instance_callback: function (editor) {
                editor.on('Change', function (e) {
                    $("#" + editor.id).val(editor.getContent());
                });
            },
            setup : function(ed)
            {
                ed.on('init', function()
                {
                    this.getDoc().body.style.fontSize = '17px';
                });
            }
        });

    };

    this.bindEvents();
};
